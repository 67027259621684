import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useIdleTimer } from 'react-idle-timer';

import parseJwt from 'Utilities/parseJwt';
import {
  permittedRoutes,
  permittedPaymentRoutes,
  permittedExpiryRoutes
}  from 'Utilities/permittedRoutes';

import * as asyncActions from 'Store/actions/auth/asyncActions';
import bindActionCreators from 'Utilities/bindActionCreators';
import { useDispatch } from 'react-redux';

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = localStorage.getItem('accessToken');
  const token = localStorage.getItem('token');
  const user = token && parseJwt(token);
  const paymentStatus = localStorage.getItem('paymentStatus');

  const dispatch = useDispatch();
  const { Signout } = bindActionCreators(asyncActions, dispatch);
  function onIdle() {
    toast.error(`You have been logged out due to inactivity. Please sign in again.`);
    Signout();
  }

  // 30 mins of idle time results in logout
  useIdleTimer({
    onIdle,
    timeout: 1000 * 60 * 30
  });

  //  cold session handling even after tab close
  window.onbeforeunload = function() {
    localStorage.clear();
    return;
  };

  const path =  user ? (
    paymentStatus ? +paymentStatus !== 3 ?  permittedPaymentRoutes[paymentStatus][0]
      : paymentStatus === 4 ? permittedExpiryRoutes[paymentStatus][0]
        : permittedRoutes[user['custom:application_role']][0]
      : (user['custom:application_role'] === '1' ? permittedPaymentRoutes[1][0] : permittedRoutes[user['custom:application_role']][0])
  ) : '/';

  const isLoggedIn = isAuthenticated && user;

  const isPaymentDone = user && (isLoggedIn && paymentStatus ? +paymentStatus !== 3
    ? permittedPaymentRoutes[paymentStatus].includes(window.location.pathname.split('/')[1])
    : paymentStatus === 4 ? permittedExpiryRoutes[paymentStatus].includes(window.location.pathname.split('/')[1])
      : permittedRoutes[user['custom:application_role']].includes(window.location.pathname.split('/')[1])
    : (user['custom:application_role'] === '1'
      ? permittedPaymentRoutes[1].includes(window.location.pathname.split('/')[1])
      : permittedRoutes[user['custom:application_role']].includes(window.location.pathname.split('/')[1])));

  return (
    <Route
      {...rest}
      render={(props) => {
        try {
          return isPaymentDone ?
            (
              <Component {...props} />
            ) : (
              <Redirect to={{ pathname: path, state: { from: props.location } }} />
            );

        } catch (error) {
          Signout();
          localStorage.clear();
          toast.error('Invalid user. Please login as a different user');
          return <Redirect to={{ pathname: '/', state: { from: props.location } }} />;
        }
      }
      }
    />
  );
};
